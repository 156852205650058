//TODO: if no policcies attached to user make no access

import routeParams from "../config/routes";

export const checkPolicy = (
  userPolicies: string[] | undefined,
  allowedPolicies: string[] | undefined
) => {
  if (allowedPolicies == undefined || allowedPolicies.length === 0) return true; //if no policies needed, then we allow public access

  if (userPolicies == undefined || userPolicies.length === 0) return false; //if user has no policies, then we allow public access TODO:make private

  return allowedPolicies.some((policy) => userPolicies.includes(policy));
};

export const getAvailableRoutes = (userPolicies: string[] | undefined) => {
  return Object.entries(routeParams)
    .filter(([key, value]) => {
      if (userPolicies === undefined) return true; //if no policies attached to user allow all
      return checkPolicy(userPolicies, routeParams[key].allowedPolicies);
    })
    .map(([key, value]) => value);
};
