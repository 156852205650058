import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useAuth } from "context/auth";
import { useApi } from "hooks/api";
import { Loader } from "components/Loader";
// Do not remove the import statements below
import { PowerBIEmbed } from "powerbi-client-react";
import { models, Report, Embed, service, Page } from "powerbi-client";
import "../styles/styles.css";
import { report } from "process";
import { th } from "date-fns/locale";
import { useParams } from "react-router-dom";
import { PowerBIReportConfig } from "navision-proxy-api/@types";

const REPORT_FILTERS_DIVIDER = "-";

// let loadedResolve: any,
//   reportLoaded = new Promise((res) => {
//     loadedResolve = res;
//   });
// let renderedResolve: any,
//   reportRendered = new Promise((res) => {
//     renderedResolve = res;
//   });

// async function embedPowerBIReport(accessToken: string, embedUrl: string) {
//   // Read report Id
//   // let embedReportId: string = REPORT_ID;
//   console.log(window);
//   const powerbi: powerbiClient.service.Service = window["powerbi"];

//   if (!powerbi) {
//     throw new Error("PowerBI client library not loaded");
//   }

//   // We give All permissions to demonstrate switching between View and Edit mode and saving report.
//   let permissions: models.Permissions = models.Permissions.All;

//   // Create the embed configuration object for the report
//   // For more information see https://go.microsoft.com/fwlink/?linkid=2153590
//   let config: models.IReportEmbedConfiguration = {
//     type: "report",
//     tokenType: models.TokenType.Embed,
//     accessToken: accessToken,
//     embedUrl: embedUrl,
//     //id: embedReportId,
//     permissions: permissions,
//     settings: {
//       panes: {
//         filters: {
//           visible: true,
//         },
//         pageNavigation: {
//           visible: true,
//         },
//       },
//     },
//   };

//   // Get a reference to the embedded report HTML element
//   let embedContainer: HTMLElement | null =
//     document.getElementById("embedContainer");

//   if (embedContainer) {
//     // Embed the report and display it within the div container.
//     const report = powerbi.embed(
//       embedContainer,
//       config
//     ) as powerbiClient.Report;

//     // report.off removes all event handlers for a specific event
//     report.off("loaded");

//     // report.on will add an event handler
//     report.on("loaded", function () {
//       loadedResolve();
//       report.off("loaded");
//     });

//     // report.off removes all event handlers for a specific event
//     report.off("error");

//     report.on(
//       "error",
//       function (event: powerbiClient.service.ICustomEvent<any>) {
//         console.log(event.detail);
//       }
//     );

//     // report.off removes all event handlers for a specific event
//     report.off("rendered");

//     // report.on will add an event handler
//     report.on("rendered", function () {
//       renderedResolve();
//       report.off("rendered");
//     });

//     return report;
//   } else {
//     throw new Error("Embed html container not found");
//   }
// }

// Insert here the code you want to run after the report is rendered

export default () => {
  const { workspaceId, reportId } = useParams<{
    workspaceId: string;
    reportId: string;
  }>();

  const { getEmbededPublicBiReportInfo } = useApi();
  const [error, setError] = React.useState("");
  // const [loading, setLoading] = React.useState(true);
  // const [report, setReport] = React.useState<Report>();
  // Track Report embedding status
  // const [isEmbedded, setIsEmbedded] = React.useState<boolean>(false);
  const [reportConfig, setReportConfig] = React.useState<{
    accessToken: string;
    embedUrl: string;
    config?: PowerBIReportConfig;
  }>();

  React.useEffect(() => {
    loadReport();
  }, []);

  const loadReport = async () => {
    try {
      const reportInfo = await getEmbededPublicBiReportInfo(
        workspaceId,
        reportId
      );
      setReportConfig({
        accessToken: reportInfo.accessToken,
        embedUrl: reportInfo.embedUrl[0].embedUrl,
        config: reportInfo.reportConfig,
      });
    } catch (error) {
      setError(`Error loading report ${error}`);
    }
  };

  // const applyFilters = async () => {
  //   try {
  //     if (user.principalCode) {
  //       // Create the filter object. For more information see https://go.microsoft.com/fwlink/?linkid=2153364
  //       const filter: models.IBasicFilter = {
  //         $schema: "http://powerbi.com/product/schema#basic",
  //         filterType: models.FilterType.Basic,
  //         target: {
  //           table: "Data",
  //           column: "Principal Code",
  //         },
  //         operator: "In",
  //         values: [user.principalCode],
  //       };
  //       if (report) {
  //         await report.updateFilters(models.FiltersOperations.Add, [filter]);
  //         console.log("Report filter was added.");
  //       } else {
  //         throw new Error("Report not loaded");
  //       }
  //       // Add the filter to the report's filters.
  //     } else {
  //       throw new Error("Principal code not found");
  //     }
  //   } catch (err: any) {
  //     setError(err.message);
  //   }
  // };

  const getFilters = () => {
    try {
      const filters = reportConfig?.config?.filters
        ? Object.entries(reportConfig?.config?.filters).map(([key, value]) => ({
            $schema: "http://powerbi.com/product/schema#basic",
            filterType: models.FilterType.Basic,
            target: {
              table: key.split(REPORT_FILTERS_DIVIDER)[0],
              column: key.split(REPORT_FILTERS_DIVIDER)[1],
            },
            operator: "In",
            values: Array.isArray(value) ? value : [value],
          }))
        : [];
      console.log("Filters", filters);
      return filters;
    } catch (err: any) {
      setError(
        `Failed to parse the filters: ${err.message}. Fitlers: ${reportConfig?.config?.filters}`
      );
    }
    return [];
  };

  return (
    <div id="report-container">
      {error ? (
        <Typography>{error}</Typography>
      ) : reportConfig ? (
        <Box height={"100vh"} width="100%">
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
              //id: "<Report Id>",
              embedUrl: reportConfig?.embedUrl,
              accessToken: reportConfig?.accessToken,
              filters: getFilters(),
              tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
              settings: {
                panes: {
                  filters: {
                    expanded: false,
                    visible: false,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
            }}
            // eventHandlers={
            //   new Map([
            //     [
            //       "loaded",
            //       function () {
            //         console.log("Report loaded");
            //       },
            //     ],
            //     [
            //       "rendered",
            //       function () {
            //         console.log("Report rendered");
            //       },
            //     ],
            //     [
            //       "error",
            //       function (event: any) {
            //         console.log(event.detail);
            //       },
            //     ],
            //     ["visualClicked", () => console.log("visual clicked")],
            //     ["pageChanged", (event) => console.log(event)],
            //   ])
            // }
            cssClassName={"h-full"}
            // getEmbeddedComponent={(embeddedReport) => {
            //   setReport(embeddedReport as Report);
            // }}
          />
        </Box>
      ) : (
        <Box m={4}>
          <Loader loading={true} />
        </Box>
      )}
    </div>
  );
};
