import React from "react";
import { useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { useApi } from "hooks/api";

import createPersistedState from "use-persisted-state";
import {
  IUser,
  AppConfig,
  PowerBIReportConfig,
} from "navision-proxy-api/@types";
import { useLang, LangCode } from "context/lang";
import { LANG_CODE_CONVERSION } from "../config/languages";

const defaultAppSettings = require("navision-proxy-api/lib/constants/defaultAppSettings");

export type IAuthUser = Partial<IUser> & { token: string };

const useUser = createPersistedState<IAuthUser>("user");

interface IAuth {
  isAuthenticated: () => boolean;
  loading: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: (options?: { force?: boolean }) => Promise<void>;
  error: string;
  user: IAuthUser;
  setUser: React.Dispatch<React.SetStateAction<IAuthUser>>;
  setAppUserConfig: (config: Partial<AppConfig>) => void;
  saveAppUserConfig: (config: Partial<AppConfig>) => void;
  reports: PowerBIReportConfig[];
  restoreSettings: () => void;
  //saveCurrentUserConfig: () => void;
  switchLanguage: (lang: LangCode) => void;
  saveLocalStorageUserConfig: () => void;
}

const AuthContext = React.createContext<IAuth>({} as IAuth);

function AuthProvider(props: {
  children: React.ReactElement;
}): React.ReactElement {
  const history = useHistory();
  const [user, setUser] = useUser({
    principalCode: "",
    userId: "",
    languageCode: "",
    allowIndtrans: false,
    companyName: undefined,
    appConfig: {
      hiddenFields: {},
      columnWidths: {},
      columnsOrderOffsets: {},
      formDefaults: {},
      minimizeMenu: false,
      showOnlyMyBookings: false,
      defaultButtonsView: false,
    },
    token: "",
  } as IAuthUser);

  const [reports, setReports] = React.useState<PowerBIReportConfig[]>([]);

  const {
    login: loginApi,
    saveUserConfig,
    getUser,
    getAvailableReports,
  } = useApi();

  /** sentry config */
  Sentry.setUser({ username: user?.userId });
  React.useEffect(() => {
    const unlisten = history.listen((location, action) => {
      //recording a bug replay for a specific user
      //to find bug when this user creates a booking but the request not sends to api
      if (user?.userId == "FG429588") {
        console.log("flushing monitoring for user");
        Sentry.getReplay()?.flush();
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const { t, _setLang, lang } = useLang();

  const [error, setError] = React.useState("");

  const setAppUserConfig = (config: Partial<AppConfig>) => {
    setUser(
      (prev) =>
        ({
          ...prev,
          appConfig: { ...prev?.appConfig, ...config },
        } as IAuthUser)
    );
  };

  /** Set app config without triggering rerender so it can be saved instantly to server */
  const saveAppUserConfig = (config: Partial<AppConfig>) => {
    const localStorageUserString = localStorage.getItem("user");
    if (localStorageUserString) {
      const localStorageUser = JSON.parse(localStorageUserString);
      localStorageUser.appConfig = { ...localStorageUser.appConfig, ...config };
      localStorage.setItem("user", JSON.stringify(localStorageUser));
      saveLocalStorageUserConfig();
    }
  };

  const [loading, setLoading] = React.useState(false);

  const isAuthenticated = () => Boolean(user?.userId);

  const refreshUserData = async () => {
    if (user?.userId) {
      const userData = await getUser(user?.userId);
      setUser({ ...userData, token: user?.token });
      //This triggers a loop
      // if (localStorage.getItem("userLanguage") !== userData?.languageCode) {
      //   setUserLang(userData);
      // }
    }
  };

  React.useEffect(() => {
    refreshUserData();
  }, []);

  //check for available reports when user logged in
  React.useEffect(() => {
    const fetchReports = async () => {
      //Fetch reports from API
      let reports = await getAvailableReports(user);

      //add user specific report
      //TODO: use only global report settings - now report is settable both globally and per user
      if (user.powerBIReportConfig) {
        reports = [...reports, user.powerBIReportConfig];
      }
      if (reports && reports.length > 0) {
        setReports(reports);
      }
    };

    if (user.userId) {
      fetchReports();
    }
  }, [user]);

  const setUserLang = (latestUser: any) => {
    _setLang(
      (LANG_CODE_CONVERSION[
        (latestUser?.languageCode?.toUpperCase() as keyof typeof LANG_CODE_CONVERSION) ||
          "ENG"
      ] as LangCode) || "en"
    );
  };

  const saveLocalStorageUserConfig = async () => {
    const localStorageUserString = localStorage.getItem("user");

    if (localStorageUserString) {
      const localStorageUser = JSON.parse(localStorageUserString) as IUser;
      localStorageUser.languageCode = lang;
      if (localStorageUser?.userId) {
        await saveUserConfig(localStorageUser);
      }
    }
  };

  // React.useEffect(() => {
  //   console.log("save config effect", user);
  //   saveLocalStorageUserConfig();
  // }, [user?.appConfig?.columnWidths]);

  // const saveCurrentAppConfigDebounced = React.useRef(
  //   debounce(saveLocalStorageUserConfig, 1000 * 10)
  // ).current;

  // //we don't want to save app config on initial render and after app initialy loads app user config
  // // so we pass first 2 times
  // // TODO: run save app config only when user does some changes
  // // now counter not working, because app runs setAppUserConfig more often
  // const saveAppConfigCounter = React.useRef(2);

  // React.useEffect(() => {
  //   console.log("trying to trigger save user conf");
  //   if (saveAppConfigCounter.current == 0) {
  //     console.log("trigger saving");

  //     saveCurrentAppConfigDebounced();
  //   } else {
  //     saveAppConfigCounter.current--;
  //   }
  // }, [user?.appConfig]);

  // /** Cleanup debounce */
  // React.useEffect(() => {
  //   return () => {
  //     saveCurrentAppConfigDebounced.cancel();
  //   };
  // }, [saveCurrentAppConfigDebounced]);

  const login = async (username: string, password: string) => {
    setLoading(true);
    try {
      const _user = await loginApi(username, password);
      setUser(_user);

      setUserLang(_user);
      history.push("/");
    } catch (error: any) {
      setError(error.messsage);
    }
    setLoading(false);
  }; // make a login request

  const logout = async (options?: { force?: boolean }) => {
    // history.push("/bookings"); //hacky solution to run all the unmount effects before logout
    if (user && !options?.force) {
      await saveLocalStorageUserConfig();
    }
    localStorage.clear();
    setUser({} as IAuthUser);
    history.push("/login");
  };

  const restoreSettings = async () => {
    let defaultAppConfig;
    //TODO: make user.defaultAppConfig field work so you can get default settings not directly from api
    if (user?.companyName == "Alex Andersen Frugt & Grønt") {
      defaultAppConfig = defaultAppSettings.defaultAAFG;
    } else if (user?.companyName == "Alex Andersen Holland") {
      defaultAppConfig = defaultAppSettings.defaultHolland;
    } else {
      defaultAppConfig = defaultAppSettings.defaultRegular;
    }

    if (defaultAppConfig) {
      const newUser = await saveUserConfig({
        ...user,
        appConfig: defaultAppConfig,
      });
      setAppUserConfig(newUser.appConfig || {});
    }
  };

  /** Use this function for switching language instead of setLang
   * so it is saves the user app language on the backend
   */
  const switchLanguage = async (newLang: LangCode) => {
    await saveUserConfig({ ...user, languageCode: newLang });
    _setLang(newLang); //this will reload this hook and reload the user with saved laguaage previously
  };

  //Add the userDepartmentName to this object
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loading,
        login,
        logout,
        error,
        user,
        setUser,
        reports,
        setAppUserConfig,
        saveAppUserConfig,
        restoreSettings,
        // saveCurrentUserConfig,
        switchLanguage,
        saveLocalStorageUserConfig,
      }}
      {...props}
    />
  );
}

const checkAuth = () => !!localStorage.getItem("userId");

const useAuth = () => React.useContext(AuthContext);

export { useAuth, AuthProvider };
