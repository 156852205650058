import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  ThemeProvider,
  createTheme,
  ThemeOptions,
} from "@material-ui/core/styles";

import Body from "./components/Body";
import Login from "./containers/Login";
import { LangProvider } from "./context/lang";
import { StoreProvider } from "./context/store";
import { SnackbarProvider } from "./context/snackbar";
import { AuthProvider } from "context/auth";

import "styles/styles.css";
import "./utils/latinise";

import Protected from "components/Protected";
import { FieldsSettingsProvider } from "context/fieldsSettings";
import ErrorBoundary from "components/ErrorBoundary";
import BookingDetails from "containers/BookingDetails";
import routeParams from "config/routes";
import { Box } from "@material-ui/core";
import ReportPublic from "components/ReportPublic";

function App() {
  return (
    <ThemeProvider theme={webookTheme}>
      <Router>
        <LangProvider>
          <SnackbarProvider>
            <AuthProvider>
              <ErrorBoundary>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route exact path={routeParams.bookingDetailsPublic.path}>
                    <Box m={4}>
                      <BookingDetails publicMode />
                    </Box>
                  </Route>
                  <Route exact path={routeParams.reportPublic.path}>
                    <ReportPublic />
                  </Route>
                  <Route exact path="/test">
                    test
                  </Route>
                  <Protected>
                    <FieldsSettingsProvider>
                      <StoreProvider>
                        <Body />
                      </StoreProvider>
                    </FieldsSettingsProvider>
                  </Protected>
                </Switch>
              </ErrorBoundary>
            </AuthProvider>
          </SnackbarProvider>
        </LangProvider>
      </Router>
    </ThemeProvider>
  );
}

const webookTheme = createTheme({
  palette: {
    primary: { main: "#008935", light: "#b3ffd0" },
    secondary: { main: "rgba(226, 56, 63,1)", light: "rgba(226, 56, 63,0.05)" },
    warning: { light: "#fff9de", main: "#ff9800", dark: "#f57c00" },
    error: { main: "#E2383F" },
    text: { primary: "rgba(49, 49, 49, 1)" },
    default: { main: "rgba(0, 0, 0, 0.54)" },
  },
  shape: { borderRadius: 1 },
  typography: {
    fontFamily: "'Raleway','Roboto', 'Helvetica', 'Arial', sans-serif",
    button: {
      textTransform: "none",
    },
    body1: {
      "@media (max-width:600px)": { fontSize: "0.7rem" },
    },
    body2: {
      "@media (max-width:600px)": { fontSize: "0.6rem" },
    },
    caption: {
      "@media (max-width:600px)": { fontSize: "0.65rem" },
    },
    h4: {
      "@media (max-width:600px)": { fontSize: "1.5rem" },
      fontWeight: 500,
    },
  },
  overrides: {
    MuiButton: { root: { minWidth: 0, justifyContent: "space-between" } },
    MuiSelect: {
      outlined: { paddingRight: "60px" },
      root: { paddingRight: "60px !important" },
    },
  },
  "@global": {
    "*::-webkit-scrollbar": {
      width: "1em",
      height: "1em",
    },
  },
  props: {
    MuiTypography: {
      noWrap: true,
    },
  },
} as ThemeOptions);

export default App;
