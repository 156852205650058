import React from "react";
import Typo from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";

import FrameSideNavigation from "components/Help/FrameSideNavigation";
import Guidelines from "containers/Guidelines";
import { useLang } from "context/lang";

import myCompany1_en from "assets/img/help/myCompany1_en.png";
import myCompany2_en from "assets/img/help/myCompany2_en.png";
import bookings1_en from "assets/img/help/bookings1_en.png";
import bookings2_en from "assets/img/help/bookings2_en.png";
import bookings3_en from "assets/img/help/bookings3_en.png";
import adjustColumns1_en from "assets/img/help/adjustColumns1_en.png";
import adjustColumns2_en from "assets/img/help/adjustColumns2_en.png";

import myCompany1_da from "assets/img/help/myCompany1_da.png";
import myCompany2_da from "assets/img/help/myCompany2_da.png";
import bookings1_da from "assets/img/help/bookings1_da.png";
import bookings2_da from "assets/img/help/bookings2_da.png";
import bookings3_da from "assets/img/help/bookings3_da.png";
import adjustColumns1_da from "assets/img/help/adjustColumns1_da.png";
import adjustColumns2_da from "assets/img/help/adjustColumns2_da.png";
import { useApi } from "hooks/api";
import { Loader } from "components/Loader";

const myCompany1 = {
  en: myCompany1_en,
  da: myCompany1_da,
  de: myCompany1_en,
  sv: myCompany1_en,
  nl: myCompany1_en,
};
const myCompany2 = {
  en: myCompany2_en,
  da: myCompany2_da,
  de: myCompany2_en,
  sv: myCompany2_en,
  nl: myCompany2_en,
};
const bookings1 = {
  en: bookings1_en,
  da: bookings1_da,
  de: bookings1_en,
  sv: bookings1_en,
  nl: bookings1_en,
};
const bookings2 = {
  en: bookings2_en,
  da: bookings2_da,
  de: bookings2_en,
  sv: bookings2_en,
  nl: bookings2_en,
};
const bookings3 = {
  en: bookings3_en,
  da: bookings3_da,
  de: bookings3_en,
  sv: bookings3_en,
  nl: bookings3_en,
};
const adjustColumns1 = {
  en: adjustColumns1_en,
  da: adjustColumns1_da,
  de: adjustColumns1_en,
  sv: adjustColumns1_en,
  nl: adjustColumns1_en,
};
const adjustColumns2 = {
  en: adjustColumns2_en,
  da: adjustColumns2_da,
  de: adjustColumns2_en,
  sv: adjustColumns2_en,
  nl: adjustColumns2_en,
};

const Img = (props: any) => {
  const theme = useTheme();
  return (
    <img
      {...props}
      style={{
        maxWidth: "100%",
        border: `${theme.palette.secondary.main} solid 1px`,
      }}
    />
  );
};

const Typography = (props: any) => {
  return (
    <Box my={2}>
      <Typo
        dangerouslySetInnerHTML={{
          __html: `<span>${props.children}</span>`,
        }}
        noWrap={false}
      ></Typo>
    </Box>
  );
};

export default () => {
  const { t, lang } = useLang();
  const { fetchHelp } = useApi();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<
    { key: string; title: string; body: string }[]
  >([]);

  React.useEffect(() => {
    const loadHelp = async () => {
      setLoading(true);
      const help = await fetchHelp(lang);
      setContent(
        help.map((h) => ({
          key: h.title,
          title: h.title,
          body: h.content,
        }))
      );
      setLoading(false);
    };
    loadHelp();
  }, []);

  // const content = [
  //   {
  //     key: "settings",
  //     title: t("myCompany"),
  //     body: (
  //       <Box>
  //         <Typography>{t("myCompany1")}</Typography>
  //         <Img src={myCompany1[lang]} />
  //         <Typography>{t("myCompany2")}</Typography>
  //         <Img src={myCompany2[lang]} />
  //         <Typography>{t("myCompany3")}</Typography>
  //       </Box>
  //     ),
  //   },
  //   {
  //     key: "adjustColumns",
  //     title: t("adjustColumns"),
  //     body: (
  //       <Box>
  //         <Typography>{t("adjustColumns1")}</Typography>
  //         <Img src={adjustColumns1[lang]} />
  //         <Typography>{t("adjustColumns2")}</Typography>
  //         <Img src={adjustColumns2[lang]} />
  //       </Box>
  //     ),
  //   },
  //   {
  //     key: "bookings",
  //     title: t("bookingsFeatures"),
  //     body: (
  //       <Box>
  //         <Typography>{t("bookingsFeatures1")}</Typography>
  //         <Img src={bookings1[lang]} />
  //         <Typography>{t("bookingsFeatures2")}</Typography>
  //         <Img src={bookings2[lang]} />
  //         <Typography>{t("bookingsFeatures3")}</Typography>
  //         <Img src={bookings3[lang]} />
  //         <Typography>{t("bookingsFeatures4")}</Typography>
  //       </Box>
  //     ),
  //   },
  //   {
  //     key: "createBooking",
  //     title: t("createBookingFeatures"),
  //     body: (
  //       <Box>
  //         <Typography>{t("createBookingFeatures1")}</Typography>
  //         <Typography>{t("createBookingFeatures2")}</Typography>
  //         <Typography>{t("createBookingFeatures3")}</Typography>
  //       </Box>
  //     ),
  //   },
  //   {
  //     key: "quickForm",
  //     title: t("quickFormFeatures"),
  //     body: (
  //       <Box>
  //         <Typography>{t("quickFormFeatures1")}</Typography>
  //         <Typography>{t("quickFormFeatures2")}</Typography>
  //       </Box>
  //     ),
  //   },
  //   {
  //     key: "guides",
  //     title: t("guidesForWebooking"),
  //     body: <Guidelines />,
  //   },
  // ];
  return (
    <Loader size={50} margin={30} loading={loading}>
      <Box mt={"50px"}>
        {content.length == 0 ? (
          <Typography>
            {t(
              "No help content for your langugage yet... Try changing language to English"
            )}
          </Typography>
        ) : (
          <FrameSideNavigation
            header={t("help")}
            subheader={t("helpHeader")}
            text={t("helpSubheader")}
            content={content}
          />
        )}
      </Box>
    </Loader>
  );
};
