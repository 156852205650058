import { Typography } from "@material-ui/core";
import { useAuth } from "context/auth";
import { useLang } from "context/lang";
import { useApi } from "hooks/api";
import { PowerBIReportConfig } from "navision-proxy-api/@types";
import React from "react";
import { Link, Redirect } from "react-router-dom"; // Import Link

export const Reports = () => {
  const { user, reports } = useAuth();

  const { t } = useLang();
  console.log(reports);

  if (reports.length > 1) {
    return (
      <div>
        <Typography>{t("chooseReport")}</Typography>
        {reports.map((report) => (
          <Link to={`/report/${report.workspaceId}/${report.reportId}`}>
            <Typography color="primary">
              {report.name || report.reportId}
            </Typography>
          </Link>
        ))}
      </div>
    );
  }
  if (reports.length == 1) {
    return (
      <Redirect
        to={`/report/${reports[0].workspaceId}/${reports[0].reportId}`}
      />
    );
  }
  return <Typography>{t("noReports")}</Typography>;
};
